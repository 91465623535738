<template>
    <div :style="mainContentTopPadding">
        <slot name="override">
            <main id="main" class="mon-main">
                <slot />
            </main>
        </slot>
    </div>
</template>

<script lang="ts" setup>

const mainContentTopPadding = useStateMainContentTopPadding()

</script>

<style lang="scss" scoped>

.mon-main {
    padding-top: 1.25rem;

    @include more-than(md) {
        padding-top: 2.5rem;
    }

    @include more-than(xl) {
        padding-top: 3.125rem;
    }
}

</style>
